import React, {useEffect, useState} from 'react';
import HeadingTwo from "./ui/heading-two";
import {Gauge, gaugeClasses} from "@mui/x-charts/Gauge";
import * as XLSX from "xlsx";
import LinearProgressWithLabel from "./ui/LinearProgressWithLabel";
import styles from "./localis-benchmark.module.css"
import api from "../../api";

export default function LocalisBenchmark({businessName}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [results, setResults] = useState([])
    const [gmb, setGmb] = useState();
    const [backlinkOverview, setBacklinkOverview] = useState();
    const [competitorsOrganicSearch, setCompetitorsOrganicSearch] = useState([]);
    const [domainOverview, setDomainOverview] = useState();
    const [competitorsGmbInfo, setCompetitorsGmbInfo] = useState([]);
    const [competitorsBacklinksOverview, setCompetitorsBacklinksOverview] = useState([]);
    const [competitors, setCompetitors] = useState([]);
    const [competitorsDomainOverview, setCompetitorsDomainOverview] = useState([]);
    const [keywordOverviewOrganic, setKeywordOverviewOrganic] = useState([]);
    const [competitorsPagespeed, setCompetitorsPagespeed] = useState([]);
    const [competitorsStructuredData, setCompetitorsStructuredData] = useState([]);
    const [pageSpeed, setPageSpeed] = useState();
    const [mapImage, setMapImage] = useState();
    const [structuredData, setStructuredData] = useState();
    const [homepageMetaDescription, setHomepageMetaDescription] = useState();
    const [homepageTitleTag, setHomepageTitleTag] = useState();
    const [crawler, setCrawler] = useState();
    const [pageTitleDuplicate, setPageTitleDuplicate] = useState({});
    const [missingMetaDescription, setMissingMetaDescription] = useState({});
    const [h2TitleDuplicate, setH2TitleDuplicate] = useState({});
    const [h1TitleDuplicate, setH1TitleDuplicate] = useState({});
    const [missingAltImagesDesc, setMissingAltImagesDesc] = useState({});
    const [isSeoFriendly, setIsSeoFriendly] = useState({});
    const [isRedirect, setIsRedirect] = useState({});
    const [clientErrors, setClientErrors] = useState({});
    const [socialMedia, setSocialMedia] = useState([]);
    const [blogPosts, setBlogPosts] = useState();
    const [competitorsBlogPosts, setCompetitorsBlogPosts] = useState([]);
    const [backlinks, setBacklinks] = useState([]);
    const [progress, setProgress] = React.useState(0);
    const [totalFaults, setTotalFaults] = useState(0);
    const [googleReviewsPercentage, setGoogleReviewsPercentage] = useState(0);
    const [data, setData] = useState([]);
    const [benchmarkSector, setBenchmarkSector] = useState(null);
    const [backlinkPercentage, setBacklinkPercentage] = useState(0);
    const [websitePagesPercentage, setWebsitePagesPercentage] = useState(0);
    const [technicalCompliancePercentage, setTechnicalCompliancePercentage] = useState(0);
    const [pagespeedPercentage, setPagespeedPercentage] = useState(0);
    const [overallBenchmark, setOverallBenchmark] = useState(0);
    const [competitorsDfs, setCompetitorsDfs] = useState([]);
    const [socialMediaPercentage, setSocialMediaPercentage] = useState(0);
    const [localLinksPercentage, setLocalLinksPercentage] = useState(0);

    const W1 = 20;
    const W2 = 10;
    const W3 = 25;
    const W4 = 15;
    const W5 = 15;
    const W6 = 10;
    const W7 = 5;

    useEffect(() => {
        if (benchmarkSector === null) {
            if (businessName) {
                handleFetchData(businessName);
            }
        }
    }, [businessName, benchmarkSector]);

    const handleFetchData = (businessName) => {
        // Fetch data logic
        const incrementProgress = (target) => {
            setProgress((prevProgress) => {
                if (prevProgress < target) {
                    return prevProgress + Math.floor(Math.random() * 3);
                } else {
                    clearInterval(interval);
                    return prevProgress;
                }
            });
        };

        let interval = setInterval(() => incrementProgress(52), 1900);
        let firstResponseReceived = false;

        const urls = [
            `https://localis.tech/localisbest/api/seo-audit/${businessName}%20Midleton/false/ie/Ireland/en-GB`,
            `https://localis.tech/localisbest/crawl/api/seo-audit/${businessName}%20Midleton/false/en-GB`
        ];
        setLoading(true);

        const fetchPromises = urls.map((url, index) =>
            fetch(url).then(response => {
                if (response.status === 404) {
                    throw new Error(`404: We did not find a GMB profile linked to your business.`);
                } else if (response.status === 504) {
                    throw new Error(`504: The server is currently unavailable. Please try again later.`);
                }
                return response;
            })
                .then(data => {
                    if (!firstResponseReceived) {
                        firstResponseReceived = true;
                        clearInterval(interval);
                        setProgress(55);
                        interval = setInterval(() => incrementProgress(97), 1900);
                    } else {
                        clearInterval(interval);
                        setProgress(100);
                        setTimeout(() => setLoading(false), 1500);
                    }
                    return data;
                })
        );

        Promise.all(fetchPromises)
            .then(responses => Promise.all(responses.map(response => response.json())))
            .then(data => {
                // Process the data
                if (data) {
                    setResults(data)
                    setGmb(data[0]['gmb']);
                    if (data[0]['backlinks_overview']) {
                        setBacklinkOverview(data[0]['backlinks_overview'][0])
                    }
                    setDomainOverview(data[0]['domain_overview'])
                    setCompetitorsBacklinksOverview(data[0]['competitors_backlinks_overview'])
                    setPageSpeed(data[0]['pagespeed'])
                    setCompetitorsPagespeed(data[0]["competitors_pagespeed"])
                    setMapImage(data[0]["map_image"])
                    setStructuredData(data[0]["structured_data"])
                    setCompetitorsStructuredData(data[0]["competitors_structured_data"])
                    setHomepageMetaDescription(data[0]["homepage_meta_description"])
                    setHomepageTitleTag(data[0]["homepage_title_tag"])
                    setCrawler(data[1]["website_crawler"])
                    setSocialMedia(data[0]["social_media"])
                    setBlogPosts(data[0]["blog_posts"])
                    setCompetitorsBlogPosts(data[0]["competitors_blog_posts"])
                    setBacklinks(data[0]["backlinks"])
                    if (data[0]["keyword_overview"]) {
                        setKeywordOverviewOrganic(data[0]["keyword_overview"])
                    }
                    if (data[0]['competitors']) {
                        setCompetitors(data[0]['competitors']["places"])
                        setCompetitorsDomainOverview(data[0]["competitors_domain_overview"])
                    } else if (data[0]['competitors_gmb_info']) {
                        setCompetitorsOrganicSearch(data[0]['competitors_organic_search'])
                        setCompetitorsGmbInfo(data[0]['competitors_gmb_info'])
                    } else if (data[0]['competitors_dfs']) {
                        setCompetitorsDfs(data[0]['competitors_dfs'])
                        setCompetitorsDomainOverview(data[0]["competitors_domain_overview"])
                    }
                    if (data.error) {
                        setError('Enter a valid business name. Please try again.')
                    }
                } else {
                    setError('Enter a valid business name. Please try again.')
                }
            })
            .catch(error => {
                // Handle any errors
                if (error.message.includes('404')) {
                    setError(`404: We did not find a GMB profile linked to your business.`);
                } else if (error.message.includes('504')) {
                    setError(`504: The server is currently unavailable. Please try again later.`);
                } else if (error.message.includes('HTTP error')) {
                    setError(`We encountered a problem with the server: ${error.message}`);
                } else if (error.message.includes('Unexpected token')) {
                    setError('We received an invalid response from the server.');
                } else {
                    setError('We encountered a problem. Try again later.');
                }
                clearInterval(interval);
                setProgress(100);
                setTimeout(() => setLoading(false), 1500);
            });
    }

    let cat1 = "none";

    if (gmb && gmb.category) {
        cat1 = gmb.category
    }

    const checkForDuplicates = (array) => {
        const counts = {};
        let numberOfDuplicates = 0;

        for (const item of array) {
            counts[item] = (counts[item] || 0) + 1;
        }

        for (const count in counts) {
            if (counts[count] > 1) {
                numberOfDuplicates += counts[count] - 1;
            }
        }

        if (numberOfDuplicates > 0) {
            return {status: "Fail", description: numberOfDuplicates};
        } else {
            return {status: "Pass", description: 0};
        }
    };

    const countFalseValues = (array) => {
        const falseCount = array.filter(item => item === false).length;

        return falseCount > 0
            ? {status: "Fail", description: falseCount}
            : {status: "Pass", description: 0};
    };

    const countTrueValues = (array) => {
        const trueCount = array.filter(item => item === true).length;

        return trueCount > 0
            ? {status: "Fail", description: trueCount}
            : {status: "Pass", description: 0};
    };

    useEffect(() => {
        if (crawler) {
            if (crawler.page_titles && crawler.page_titles.length) {
                setPageTitleDuplicate(checkForDuplicates(crawler.page_titles));
            }
            if (crawler.meta_descriptions && crawler.meta_descriptions.length) {
                setMissingMetaDescription(countFalseValues(crawler.meta_descriptions));
            }
            if (crawler.h2_tags && crawler.h2_tags.length) {
                setH2TitleDuplicate(checkForDuplicates(crawler.h2_tags));
            }
            if (crawler.h1_tags && crawler.h1_tags.length) {
                setH1TitleDuplicate(checkForDuplicates(crawler.h1_tags));
            }
            if (crawler.no_image_alt && crawler.no_image_alt.length) {
                setMissingAltImagesDesc(countTrueValues(crawler.no_image_alt));
            }
            if (crawler.is_seo_friendly && crawler.is_seo_friendly.length) {
                setIsSeoFriendly(countFalseValues(crawler.is_seo_friendly));
            }
            if (crawler.is_4xx_code && crawler.is_4xx_code.length) {
                setClientErrors(countTrueValues(crawler.is_4xx_code));
            }
            if (crawler.is_redirect && crawler.is_redirect.length) {
                setIsRedirect(countTrueValues(crawler.is_redirect));
            }

            const faults = pageTitleDuplicate.description +
                missingMetaDescription.description +
                h2TitleDuplicate.description +
                h1TitleDuplicate.description +
                missingAltImagesDesc.description +
                isSeoFriendly.description +
                clientErrors.description +
                isRedirect.description;

            setTotalFaults(faults);
        }
    }, [crawler, clientErrors.description, h1TitleDuplicate.description, h2TitleDuplicate.description,
        isRedirect.description, isSeoFriendly.description, missingAltImagesDesc.description,
        missingMetaDescription.description, pageTitleDuplicate.description]);

    useEffect(() => {
        // Fetch the XLSX file
        fetch('LocalIs_GMB_Category_Benchmark_2024(CJ).xlsx')
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => {

                const workbook = XLSX.read(arrayBuffer, {type: 'array'});
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
                setData(jsonData);
            });
    }, []);

    useEffect(() => {
        if (competitors && gmb && gmb.rating) {
            const highestUserRatingCount = competitors.reduce((maxUserRatingCount, competitor) => {
                const userRatingCount = competitor.userRatingCount || 0;
                return userRatingCount > maxUserRatingCount ? userRatingCount : maxUserRatingCount;
            }, 0);

            let GoogleReviewsPercentage = (gmb.rating.votes_count / highestUserRatingCount) * 100;

            if (GoogleReviewsPercentage > 100) {
                GoogleReviewsPercentage = 100;
            }

            setGoogleReviewsPercentage(GoogleReviewsPercentage)
        } else if (competitorsGmbInfo && gmb && gmb.rating) {
            const highestUserRatingCount = competitorsGmbInfo.reduce((maxUserRatingCount, competitor) => {
                const userRatingCount = competitor.userRatingCount || 0;
                return userRatingCount > maxUserRatingCount ? userRatingCount : maxUserRatingCount;
            }, 0);

            let GoogleReviewsPercentage = (gmb.rating.votes_count / highestUserRatingCount) * 100;

            if (GoogleReviewsPercentage > 100) {
                GoogleReviewsPercentage = 100;
            }

            setGoogleReviewsPercentage(GoogleReviewsPercentage)
        } else if (competitorsDfs && gmb && gmb.rating) {
            const highestUserRatingCount = competitorsDfs.reduce((maxUserRatingCount, competitor) => {
                const userRatingCount = competitor.userRatingCount || 0;
                return userRatingCount > maxUserRatingCount ? userRatingCount : maxUserRatingCount;
            }, 0);

            let GoogleReviewsPercentage = (gmb.rating.votes_count / highestUserRatingCount) * 100;

            if (GoogleReviewsPercentage > 100) {
                GoogleReviewsPercentage = 100;
            }

            setGoogleReviewsPercentage(GoogleReviewsPercentage)
        } else if (gmb && gmb.rating) {
            let GoogleReviewsPercentage = (gmb.rating.votes_count / 0) * 100;

            if (GoogleReviewsPercentage > 100) {
                GoogleReviewsPercentage = 100;
            }

            setGoogleReviewsPercentage(GoogleReviewsPercentage)
        }

    }, [competitors, gmb, competitorsGmbInfo, competitorsDfs]);

    useEffect(() => {
        if (data.length > 0 && gmb) {
            // Convert the first row to column headers
            const headers = data[0];
            const rows = data.slice(1);
            const categoryIndex = headers.indexOf('Primary Category');
            const benchmarkIndex = headers.indexOf('Benchmark');

            if (categoryIndex > -1 && benchmarkIndex > -1) {
                const categoryData = rows.find(row => row[categoryIndex] === gmb.category);
                if (categoryData) {
                    setBenchmarkSector(categoryData[benchmarkIndex]);
                } else {
                    setBenchmarkSector(45);
                }
            } else {
                setBenchmarkSector(45);
            }
        }
    }, [data, gmb]);

    useEffect(() => {
        if (competitorsBacklinksOverview && backlinkOverview) {
            const highestCompetitorTotal = competitorsBacklinksOverview.reduce((max, competitor) => {
                let competitorBacklinks;
                if (competitor[0]) {
                    competitorBacklinks = competitor[0].total
                } else {
                    competitorBacklinks = 0
                }
                const total = parseInt(competitorBacklinks, 10);
                return total > max ? total : max;
            }, 0);

            const myBacklinksTotal = parseInt(backlinkOverview.total, 10);
            let percentage = (myBacklinksTotal / highestCompetitorTotal) * 100;

            percentage = customRound(percentage);
            setBacklinkPercentage(percentage);
        } else {
            setBacklinkPercentage(0);
        }

        if (crawler) {
            const pagesCrawled = parseInt(crawler.total_urls, 10);
            let pagesPercentage = (pagesCrawled / 30) * 10;

            if (pagesPercentage > 100) {
                pagesPercentage = 100;
            }

            pagesPercentage = customRound(pagesPercentage);
            setWebsitePagesPercentage(pagesPercentage);
        } else {
            setWebsitePagesPercentage(0);
        }

        if (totalFaults && crawler) {
            let technicalCompliance = customRound((1 - (totalFaults / crawler.number_pages_crawled)) * 100);
            if (technicalCompliance < 0) {
                setTechnicalCompliancePercentage(0)
            } else if (technicalCompliance > 100) {
                setTechnicalCompliancePercentage(100)
            } else {
                setTechnicalCompliancePercentage(technicalCompliance)
            }
        } else {
            setTechnicalCompliancePercentage(0)
        }

        if (!pageSpeed) {
            setPagespeedPercentage(0)
        } else if (pageSpeed && pageSpeed.error) {
            setPagespeedPercentage(0)
        } else {
            setPagespeedPercentage(Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100))
        }

        if (!socialMedia) {
            setSocialMediaPercentage(0)
        } else if (socialMedia && Array.isArray(socialMedia)) {
            const ownedAccounts = socialMedia.filter(account => account.owned_by_business);
            let totalOwned = ownedAccounts.length;
            if (totalOwned > 5) totalOwned = 5
            const percentageOwned = (totalOwned / 5) * 100;

            setSocialMediaPercentage(customRound(percentageOwned))
        }

        if (Array.isArray(backlinks)) {
            const numberSocialLinks = backlinks.length;

            if (numberSocialLinks === 10) {
                setLocalLinksPercentage(75)
            } else if (numberSocialLinks > 5 && numberSocialLinks < 10) {
                setLocalLinksPercentage(50)
            } else if (numberSocialLinks > 0 && numberSocialLinks < 6) {
                setLocalLinksPercentage(25)
            } else {
                setLocalLinksPercentage(0)
            }
        }
    }, [totalFaults, pageSpeed, backlinkOverview, competitorsBacklinksOverview, crawler, socialMedia, backlinks]);

    useEffect(() => {
        if (backlinkPercentage > 100) {
            setBacklinkPercentage(100)
        } else if (backlinkPercentage < 0) {
            setBacklinkPercentage(0)
        }

        if (websitePagesPercentage > 100) {
            setWebsitePagesPercentage(100)
        } else if (websitePagesPercentage < 0) {
            setWebsitePagesPercentage(0)
        }

        if (technicalCompliancePercentage > 100) {
            setTechnicalCompliancePercentage(100)
        } else if (technicalCompliancePercentage < 0) {
            setTechnicalCompliancePercentage(0)
        }

        if (pagespeedPercentage > 100) {
            setPagespeedPercentage(100)
        } else if (pagespeedPercentage < 0) {
            setPagespeedPercentage(0)
        }

        if (socialMediaPercentage > 100) {
            setSocialMediaPercentage(100)
        } else if (socialMediaPercentage < 0) {
            setSocialMediaPercentage(0)
        }

        if (localLinksPercentage > 100) {
            setLocalLinksPercentage(100)
        } else if (localLinksPercentage < 0) {
            setLocalLinksPercentage(0)
        }
        setOverallBenchmark(customRound(((W1 * backlinkPercentage) + (W2 * websitePagesPercentage) +
            (W3 * technicalCompliancePercentage) + (W4 * pagespeedPercentage) + (W7 * localLinksPercentage) +
            (W5 * customRound(googleReviewsPercentage)) + (W6 * socialMediaPercentage)) / 100))
    }, [backlinkPercentage, websitePagesPercentage, technicalCompliancePercentage, pagespeedPercentage,
        googleReviewsPercentage, socialMediaPercentage, localLinksPercentage]);

    const customRound = (number) => {
        const integerPart = Math.floor(number);
        const decimalPart = number - integerPart;
        if (decimalPart >= 0.5) {
            return Math.ceil(number);
        } else {
            return Math.floor(number);
        }
    };

    let url = api;

    function updateLocalisBenchmark() {

        // Or you can work with it as a plain object:
        const formJson = {
            status: 'approved',
            resourcesId: 'id'
        }

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/resourceStatus', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Resource published");
                    window.location.reload();
                } else {
                    setError('We couldn’t update the resource. Please try again.')
                }
            })
            .catch(error => console.error(error));

    }

    return (
        <div>
            <HeadingTwo title={'Last Local Benchmark'} className={'mt-10'}/>

            {loading && <div>
                <LinearProgressWithLabel value={progress}/>
            </div>}
            {error && <p style={{color: 'red'}}>{error}</p>}
            {
                results.length !== 0 && !loading ?
                    <div className={styles.gauge}>
                        <div className={styles.gaugeWrapper}>
                            <Gauge width={100} height={100} value={overallBenchmark}
                                   text={({value, valueMax}) => `${value}%`} sx={{
                                [`& .${gaugeClasses.valueText}`]: {fontWeight: 'bold', fontStyle: 'italic'},
                                [`& .${gaugeClasses.valueArc}`]: {fill: '#063A2E',}
                            }}/>
                            <p className={styles.gaugeText}>{businessName}</p>
                        </div>
                        {
                            cat1 !== "none" ?
                                <div className={styles.gaugeWrapper}>
                                    <Gauge width={100} height={100} value={benchmarkSector}
                                           text={({value, valueMax}) => `${value}%`} sx={{
                                        [`& .${gaugeClasses.valueText}`]: {fontWeight: 'bold', fontStyle: 'italic'},
                                        [`& .${gaugeClasses.valueArc}`]: {fill: '#A58D08',}
                                    }}/>
                                    <p className={styles.gaugeText}>{cat1} Sector</p>
                                </div> :
                                null
                        }
                    </div> :
                    null
            }
        </div>
    )
}