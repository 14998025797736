import React, {useEffect, useState} from 'react';
import styles from "./participants-table.module.css";
import {useNavigate} from "react-router-dom";
import api from "../../api";

export default function ParticipantsTable({eventsId}) {

    const [eventId, setEventId] = useState(eventsId);
    const [participants, setParticipants] = useState([]);
    const [error, setError] = useState('');

    const url = api;

    const getParticipants = async () => {
        try {

            const response = await fetch(url + 'app/getEventParticipants/' + eventId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setParticipants(data.participants);

        } catch (error) {
            console.error('Error fetching business data:', error);
            setError(error);
        }
    };

    useEffect(() => {
        getParticipants();
    }, []);

    return (
        <table className={styles.table}>
            <thead className={styles.thead}>
            <tr>
                <th>Participants Names</th>
                <th>Number of Participants</th>
                <th>Comment</th>
                <th>Email</th>
            </tr>
            </thead>
            <tbody className={styles.tbody}>
            {participants.length > 0 ?
                participants.map(participant => (
                    <tr key={participant.ParticipantsId}>
                        <td>{participant.participantsNames}</td>
                        <td>{participant.participantsNumber}</td>
                        <td>{participant.comment}</td>
                        <td>{participant.email}</td>
                    </tr>
                )) :
                <tr>
                    <td>No participants for this event.</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            }


            </tbody>
        </table>
    );
}