import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import FilterResources from "../../components/towncrier/filter-resources";
import HeadingTwo from "../../components/towncrier/ui/heading-two";
import Button from "../../components/towncrier/ui/button";

export default function ResourceLibrary() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));

    return (
        <main className={'resource-library towncrier-content'}>
            <div className='section'>
                <Heading title={'Resource Library'} />
                <p>Here you will find a variety of business resources, guides and templates.</p>
                {
                    user.role === 1 ?
                        <div className={'mb-10 mt-10'}>
                            <span className={'mr-5'}><Button href={'create-resource'} text={'Create a Resource'}/></span>
                            <Button href={'manage-resources'} text={'Manage Resources'}/>
                        </div>
                        : ''
                }
                <HeadingTwo className={''} title={'Filter'}/>
                <FilterResources />
            </div>

        </main>
    );
}